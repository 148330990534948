import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ContentfulBlogPost } from "../common/types/contentful-models";
import BlogCard from "../components/Modules/blog/blog-card";

const BlogCards = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost {
          edges {
            node {
              title
              id
              slug
              description {
                description
              }
            }
          }
        }
      }
    `
  );
  return (
    <div className="grid sm:grid-cols-3 grid-cols-1">
      {data.allContentfulBlogPost.edges.map((edge: any) => {
        const contentfulBlogPost: ContentfulBlogPost = edge.node;
        return (
          <BlogCard
            key={contentfulBlogPost.id}
            slug={contentfulBlogPost.slug}
            title={contentfulBlogPost.title}
            description={contentfulBlogPost.description}
          />
        );
      })}
    </div>
  );
};
export default BlogCards;
