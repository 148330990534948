import * as React from "react";
import { Link } from "gatsby";
import { BlogCardOverviewModel } from "../../../common/types/models";
import Title from "@components/UI-Elements/Typography/Title";

function BlogCard({ title, slug, description }: BlogCardOverviewModel) {
  const fullSlug = "/blog/" + slug;
  return (
    <div className="p-4">
      <Link to={fullSlug}>
        <div className="m-auto w-58">
          <div className="grid overflow-hidden grid-cols-3 grid-flow-row bg-white rounded-lg shadow-md transition-shadow duration-300 ease-in-out grid-rows-7 hover:shadow-xl">
            <div className="col-span-3 row-span-4">
              <img src="https://picsum.photos/640/400/?random" alt="Placeholder" className="object-cover w-full h-48 rounded-t-xl" />
            </div>

            <div className="col-span-3 row-span-1">
              <header className="flex justify-between items-center p-2 leading-tight md:p-4">
                <Title level={6} className="font-bold text-md">{title}</Title>
              </header>
            </div>

            <div className="col-span-3 row-span-1">
              <p className="overflow-hidden px-2 pb-2 text-sm md:px-4" style={{ textOverflow: "ellipsis" }}>
                {description.description}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default BlogCard;
