import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogCards from "../components/blog-cards";
import Title from "@components/UI-Elements/Typography/Title";

const BlogPage: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={['blog']} title="Blog" robots="noindex" />
      <Title level={1}>Blog</Title>
      <BlogCards />
    </Layout>
  );
};

export default BlogPage;
